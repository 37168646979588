import React from "react";
import { RouteObject } from "react-router-dom";
import { App } from "./App";
import {
  UnprotectedRoutes,
  UnprotectedRoutesLoader,
} from "./components/authentication/UnprotectedRoutes";
import { ProtectedRoutes } from "./components/authentication/ProtectedRoutes";
import { Login, LoginAction } from "./components/authentication/Login";
import { Entrypoint } from "./components/Entrypoint";
import { APP_ROUTES } from "./routes";
import { PageSettings } from "./components/PageSettings/PageSettings";

export const router: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <UnprotectedRoutes />,
        loader: UnprotectedRoutesLoader,
        children: [
          {
            index: true,
            action: LoginAction,
            element: <Login />,
          },
        ],
      },
      {
        path: APP_ROUTES.HOME,
        element: <ProtectedRoutes />,
        children: [
          {
            index: true,
            element: <Entrypoint />,
          },
          {
            path: APP_ROUTES.SETTINGS,
            element: <PageSettings />,
          },
        ],
      },
    ],
  },
];
