import { CircularProgress, Stack } from "@mui/material";
import { Card } from "@tremor/react";

interface NumberCardProps {
  isLoading: boolean;
  amount: number | string;
  title: string;
}

export const NumberCard = ({ isLoading, amount, title }: NumberCardProps) => {
  return (
    <Card className="mx-auto max-w-md p-5">
      <h4 className="text-sm font-bold">{title}</h4>
      {isLoading ? (
        <CircularProgress color="secondary" size={30} />
      ) : (
        <p className="text-base font-normal">
          <div>{amount}</div>
        </p>
      )}
    </Card>
  );
};
