import { useGetMachineStatuses } from "../../../api/endpoints/get-machine-statuses";
import { BaseProps } from "../../../utils/types";
import { MachineStatusesTable } from "../MachineStatusesTable";

interface MachineDetail extends BaseProps {
  id: number;
}

export const MachineDetail = ({ id, from, to, branches }: MachineDetail) => {
  const { data, isLoading } = useGetMachineStatuses({
    branches,
    from,
    to,
    machineIds: [id],
  });
  return (
    <div className="px-5 mt-5">
      <div className="mb-3 text-black-500 font-bold">Historial de estados</div>
      <MachineStatusesTable data={data} isLoading={isLoading} />
    </div>
  );
};
