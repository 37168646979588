import React from "react";
import { MachineStats } from "../../../api/endpoints";
import { Table } from "../../ui/Table/Table";

import { getColumns } from "./columns";
import { GridEventListener } from "@mui/x-data-grid";

interface MachineSalesTableProps {
  machineSales: MachineStats[] | undefined;
  isLoading: boolean;
  onRowClick: GridEventListener<"rowClick">;
}
export const MachineSalesTable = ({
  machineSales,
  isLoading,
  onRowClick,
}: MachineSalesTableProps) => {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });

  const { columns, rows } = React.useMemo(() => {
    if (!machineSales) {
      return { columns: [], rows: [] };
    }

    const rows = machineSales.map((item) => {
      return {
        id: item.id,
        machine_name: item.machine_name,
        machine_type_name: item.machine_type_name,
        sales: item.sales,
        uses: item.uses,
        branch_name: item.branch_name,
        is_active: item.is_active,
      };
    });

    return { columns: getColumns(), rows };
  }, [machineSales]);

  return (
    <Table
      columns={columns}
      rows={rows}
      paginationModel={paginationModel}
      setPaginationModel={setPaginationModel}
      totalRows={machineSales?.length ?? 0}
      isLoading={isLoading}
      onRowClick={onRowClick}
      getRowId={(row) => row.id}
      paginationMode="client"
    />
  );
};
