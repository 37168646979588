import { formatToMXN } from "../../../utils/money";
import { formatDate } from "../../../utils/date";
import { BaseProps } from "../../../utils/types";
import { RevenueGraph } from "../RevenueGraph";

interface RevenueByDayProps extends BaseProps {}

const sortDate = (
  a: { date: string; venta: number },
  b: { date: string; venta: number }
) => {
  return a.date.localeCompare(b.date);
};

export const RevenueByDay = ({ branches, from, to }: RevenueByDayProps) => {
  return (
    <RevenueGraph
      type="area"
      groupingFunction={formatDate}
      title={"Ingresos por dia"}
      branches={branches}
      from={from}
      to={to}
      valueFormatter={formatToMXN}
      index="date"
      sortGroupFn={sortDate}
      categories={["venta"]}
      colors={["emerald"]}
      mapper={([key, value]) => {
        return { date: key, venta: value };
      }}
    />
  );
};
