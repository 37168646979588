import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useGetOrg } from "../../api/endpoints/get-org";
import { SvgIconTypeMap } from "@mui/material";
import { APP_ROUTES } from "../../routes";
import { logout } from "../../utils/logout";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Card, Divider } from "@tremor/react";

const DRAWER_WIDTH = 200;

interface NavbarProps {
  children?: React.ReactNode;
}

export const Navbar = ({ children }: NavbarProps) => {
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const { data, isLoading } = useGetOrg({});

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const drawer = (
    <List>
      <div className="px-5 py-2">
        {isLoading ? (
          <p>...</p>
        ) : (
          <span className="font-semibold">{data?.org_name}</span>
        )}
      </div>
      <NavButton
        Icon={DashboardIcon}
        text="Dashboard"
        onClick={() => navigate(APP_ROUTES.HOME)}
      />
      <NavButton
        Icon={SettingsIcon}
        text="Cuenta"
        onClick={() => navigate(APP_ROUTES.SETTINGS)}
      />
      <Divider />
      <NavButton
        Icon={LogoutIcon}
        text="Cerrar sesión"
        onClick={async () => await logout(navigate)}
      />
    </List>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
          display: { xs: "block", md: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
            backgroundColor: "black",
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <div className="w-full mt-12 md:mt-0">{children}</div>
    </Box>
  );
};

function NavButton({
  Icon,
  text,
  onClick,
}: {
  Icon: OverridableComponent<SvgIconTypeMap>;
  text: string;
  onClick: () => void;
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <Icon className="mr-3 text-black-500" style={{ fontSize: 20 }} />
        <Typography variant="subtitle1">{text}</Typography>
      </ListItemButton>
    </ListItem>
  );
}
