import React from "react";
import {
  BarChart,
  Card,
  LineChart,
  ValueFormatter,
  AreaChart,
} from "@tremor/react";
import { useRevenueTransactions } from "../../../api/endpoints/get-revenue-transactions";
import { BaseProps } from "../../../utils/types";

interface RevenueGraphProps extends BaseProps {
  groupingFunction: (o: any) => string;
  title: string;
  valueFormatter?: ValueFormatter;
  sortGroupFn?: (a: any, b: any) => number;
  categories: string[];
  colors: string[];
  index: string;
  mapper: (o: any) => any;
  type?: "bar" | "line" | "area";
}

export const RevenueGraph = ({
  branches,
  from,
  to,
  groupingFunction,
  title,
  valueFormatter,
  sortGroupFn,
  categories,
  colors,
  index,
  mapper,
  type = "bar",
}: RevenueGraphProps) => {
  const { data, isLoading } = useRevenueTransactions({ branches, from, to });

  const { grouped } = React.useMemo(() => {
    if (!data || isLoading) {
      return { grouped: [] };
    }

    const grouped = data.reduce((acc, item) => {
      let group = groupingFunction(item.created_at);

      if (!acc[group]) {
        acc[group] = 0;
      }
      acc[group] += item.amount;
      return acc;
    }, {} as Record<string, number>);

    return {
      grouped: Object.entries(grouped).map(mapper).sort(sortGroupFn),
    };
  }, [data, isLoading, groupingFunction, mapper, sortGroupFn]);

  let graph = undefined;

  if (type === "line") {
    graph = (
      <LineChart
        data={grouped}
        index={index}
        categories={categories}
        yAxisWidth={48}
        colors={colors}
        valueFormatter={valueFormatter}
        onValueChange={(v) => console.log(v)}
      />
    );
  } else if (type === "area") {
    graph = (
      <AreaChart
        data={grouped}
        index={index}
        categories={categories}
        yAxisWidth={48}
        colors={colors}
        valueFormatter={valueFormatter}
        onValueChange={(v) => console.log(v)}
      />
    );
  } else {
    graph = (
      <BarChart
        data={grouped}
        index={index}
        categories={categories}
        yAxisWidth={48}
        colors={colors}
        valueFormatter={valueFormatter}
        onValueChange={(v) => console.log(v)}
      />
    );
  }

  return (
    <Card>
      <h3 className="text-base font-bold"> {title} </h3>
      {graph}
    </Card>
  );
};
