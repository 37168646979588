import { DateRangePickerValue, Card } from "@tremor/react";
import React, { useState } from "react";
import { useGetBranches } from "../../api/endpoints/get-branches";

import { MultiSelect } from "../MultiSelect";
import { DatePicker } from "../ui/DatePicker";
import { CURRENT_DAY, FIRST_DAY_OF_MONTH } from "../../utils/date";
import { PageRevenue } from "../revenue/PageRevenue";
import { BasicTabs } from "../ui/Tabs";
import { PageMachines } from "../machines/PageMachines";
import { PageCustomers } from "../customers/PageCustomers";

export const Entrypoint = () => {
  const token = sessionStorage.getItem("itos_token");
  const { data, isLoading } = useGetBranches({
    enabled: token !== null || token !== "",
  });
  const [value, setValue] = useState<DateRangePickerValue>({
    from: FIRST_DAY_OF_MONTH,
    to: CURRENT_DAY,
  });

  const [currentTab, setCurrentTab] = useState(0);

  const { from, to } = React.useMemo(() => {
    if (!value.from) {
      return {
        from: "",
        to: "",
      };
    }

    const from = value.from.toISOString().split("T")[0];
    const to = value.to?.toISOString().split("T")[0];

    if (!to) {
      return {
        from,
        to: from,
      };
    }

    return {
      from,
      to,
    };
  }, [value]);

  const onValueChange = (value: DateRangePickerValue) => {
    setValue(value);
  };
  const [branch, setBranch] = useState<string[]>([]);
  const onBranchChange = (value: string[]) => {
    setBranch(value);
  };

  const { selectValues, branches } = React.useMemo(() => {
    if (!data) {
      return { selectValues: [], branches: [] };
    }

    return {
      selectValues: data.map((item) => ({
        value: item.id.toString(),
        label: item.name,
      })),
      branches: branch.map((item) => item),
    };
  }, [data, branch]);

  if (isLoading) {
    return <Card className="app">Loading...</Card>;
  }

  return (
    <div className="app p-4">
      <div className="md:grid grid-cols-10 gap-x-2 mb-6">
        <div className="col-span-5 my-2 md:my-0">
          {selectValues.length > 1 && (
            <MultiSelect
              value={branch}
              values={selectValues}
              onValueChange={onBranchChange}
            />
          )}
        </div>
        <div className="col-span-2" />
        <div className="col-span-3">
          <DatePicker onValueChange={onValueChange} value={value} />
        </div>
      </div>
      <BasicTabs
        tabs={[
          {
            label: "Ingresos",
            content: <PageRevenue branches={branches} from={from} to={to} />,
          },
          {
            label: "Máquinas",
            content: <PageMachines branches={branches} from={from} to={to} />,
          },
          {
            label: "Clientes",
            content: <PageCustomers branches={branches} from={from} to={to} />,
          },
        ]}
        value={currentTab}
        onValueChange={(_, newValue) => setCurrentTab(newValue)}
      />
    </div>
  );
};
