import React from "react";
import { BaseProps } from "../../../utils/types";
import { CashVSCreditByDay } from "../CashVsCreditByDay";
import { RevenueByDay } from "../RevenueByDay";
import { RevenueByDayOfWeek } from "../RevenueByDayOfWeek";
import { RevenueByHour } from "../RevenueByHour";
import { TotalOperations } from "../TotalOperations";
import { useStats } from "../../../api/endpoints";
import { formatToMXN } from "../../../utils/money";
import { NumberCard } from "../../ui/NumberCard";
interface PageRevenueProps extends BaseProps {}

export const PageRevenue = ({ branches, from, to }: PageRevenueProps) => {
  const { data, isLoading } = useStats({
    branches,
    from,
    to,
  });
  return (
    <React.Fragment>
      <div className="grid lg:grid-flow-col lg:grid-cols-5 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.total_amount ?? 0)}
          title="Total ingresos en el kiosko"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.avg_daily_sales ?? 0)}
          title="Promedio ingresos por dia"
        />
        <NumberCard
          amount={data?.credit_card_percentage.toFixed(2) ?? 0}
          isLoading={isLoading}
          title={"% Tarjeta de credito"}
        />
        <NumberCard
          amount={data?.cash_percentage.toFixed(2) ?? 0}
          isLoading={isLoading}
          title={"% Efectivo"}
        />
        <TotalOperations branches={branches} from={from} to={to} />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-5 gap-5 my-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.avg_operation_amount ?? 0)}
          title="Promedio operacion kiosko"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.laundry_sales ?? 0)}
          title="Venta en usos de lavanderia"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.water_sales ?? 0)}
          title="Venta en usos de agua"
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(
            (data?.total_amount ?? 0) - (data?.laundry_sales ?? 0)
          )}
          title="Balance clientes"
        />
      </div>
      <div className="lg:grid grid-flow-col grid-cols-2 gap-5 my-4">
        <RevenueByDay branches={branches} from={from} to={to} />
        <RevenueByDayOfWeek branches={branches} from={from} to={to} />
      </div>
      <div className="lg:grid grid-flow-col grid-cols-2 gap-5">
        <RevenueByHour branches={branches} from={from} to={to} />
        <CashVSCreditByDay branches={branches} from={from} to={to} />
      </div>
    </React.Fragment>
  );
};
