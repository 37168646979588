import { DAYS, getDayOfWeek } from "../../../utils/date";
import { formatToMXN } from "../../../utils/money";
import { BaseProps } from "../../../utils/types";
import { RevenueGraph } from "../RevenueGraph";

interface RevenueByDayOfWeekProps extends BaseProps {}

const sortDayOfWeek = (
  a: { date: string; venta: number },
  b: { date: string; venta: number }
) => {
  return DAYS.indexOf(a.date) - DAYS.indexOf(b.date);
};

export const RevenueByDayOfWeek = ({
  branches,
  from,
  to,
}: RevenueByDayOfWeekProps) => {
  return (
    <RevenueGraph
      groupingFunction={getDayOfWeek}
      title={"Ingresos por dia de la semana"}
      branches={branches}
      from={from}
      to={to}
      valueFormatter={formatToMXN}
      index="date"
      sortGroupFn={sortDayOfWeek}
      categories={["venta"]}
      colors={["emerald"]}
      mapper={([key, value]) => {
        return { date: key, venta: value };
      }}
    />
  );
};
