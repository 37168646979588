import { useQuery } from "@tanstack/react-query";
import { instance as ax } from "../setup";
import { BaseProps } from "../../utils/types";
import { addBaseURLParams } from "./utils";

interface Response {
  data: {
    total_amount: number;
    avg_daily_sales: number;
    avg_operation_amount: number;
    cash_percentage: number;
    credit_card_percentage: number;
    laundry_sales: number;
    water_sales: number;
  };
}

const getStats = async ({ from, to, branches }: BaseProps) => {
  let url = "/api/v1/stats";
  url = addBaseURLParams(url, { from, to, branches });

  const response = await ax
    .get<Response>(url)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      return null;
    });
  return response;
};

interface TotalRevenueProps extends BaseProps {}

export const useStats = ({ branches, from, to }: TotalRevenueProps) => {
  const { data, isLoading } = useQuery({
    queryKey: ["stats", from, to, branches],
    queryFn: () =>
      getStats({
        from,
        to,
        branches,
      }),
  });

  if (!data) {
    return { data: undefined, isLoading };
  }

  return { data, isLoading };
};
