import { getHour } from "../../../utils/date";
import { formatToMXN } from "../../../utils/money";
import { BaseProps } from "../../../utils/types";
import { RevenueGraph } from "../RevenueGraph";

interface RevenueByHourProps extends BaseProps {}

export const RevenueByHour = ({ branches, from, to }: RevenueByHourProps) => {
  return (
    <RevenueGraph
      groupingFunction={getHour}
      title={"Ingresos por hora del dia"}
      branches={branches}
      from={from}
      to={to}
      valueFormatter={formatToMXN}
      index="date"
      categories={["venta"]}
      colors={["emerald"]}
      mapper={([key, value]) => {
        return { date: key, venta: value };
      }}
      sortGroupFn={(
        a: { date: string; value: number },
        b: { date: string; value: number }
      ) => {
        return parseInt(a.date) - parseInt(b.date);
      }}
    />
  );
};
