import React from "react";
import { Outlet } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "./utils/query-client";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const primary = {
  main: "#000000",
};
const secondary = {
  main: "#009688",
};

const theme = createTheme({
  palette: {
    primary,
    secondary,
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Outlet />
      </ThemeProvider>
    </QueryClientProvider>
  );
};
