import React from "react";
import { useGetMachineStats } from "../../../api/endpoints";
import { calculateDaysBetween } from "../../../utils/date";
import { formatToMXN } from "../../../utils/money";
import { BaseProps } from "../../../utils/types";
import { NumberCard } from "../../ui/NumberCard";
import { MachineSalesTable } from "../MachineSalesTable/MachineSalesTable";
import { Drawer } from "@mui/material";
import { MachineDetail } from "../MachineDetail";

interface PageMachinesProps extends BaseProps {}
export const PageMachines = ({ branches, from, to }: PageMachinesProps) => {
  const { data, isLoading } = useGetMachineStats({ branches, from, to });
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(false);
  const [machineID, setMachineID] = React.useState<number>(0);

  const { avgUsesPerDay } = React.useMemo(() => {
    if (!data) {
      return { avgUsesPerDay: 0 };
    }

    const totalUses = data.washer_uses / data.total_washers;
    const totalDays = calculateDaysBetween(from, to);
    const avgUsesPerDay = (totalUses / totalDays).toFixed(2);

    return { avgUsesPerDay };
  }, [data, from, to]);

  return (
    <div className="w-auto">
      <div className="grid lg:grid-flow-col lg:grid-cols-4 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.washer_sales ?? 0)}
          title={"Ventas Lavadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.washer_uses ?? 0}
          title={"Usos Lavadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={formatToMXN(data?.dryer_sales ?? 0)}
          title={"Ventas Secadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.dryer_uses ?? 0}
          title={"Usos Secadoras"}
        />
      </div>
      <div className="grid lg:grid-flow-col lg:grid-cols-4 gap-5 mb-4">
        <NumberCard
          isLoading={isLoading}
          amount={data?.total_washers ?? 0}
          title={"Número de lavadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={data?.total_dryers ?? 0}
          title={"Número de secadoras"}
        />
        <NumberCard
          isLoading={isLoading}
          amount={avgUsesPerDay}
          title={"Promedio de usos por dia"}
        />
      </div>
      <MachineSalesTable
        machineSales={data?.machine_sales}
        isLoading={isLoading}
        onRowClick={(params) => {
          setOpenDrawer(true);
          setMachineID(params.row.id);
        }}
      />
      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        style={{ width: "inherit" }}
      >
        <MachineDetail id={machineID} branches={branches} from={from} to={to} />
      </Drawer>
    </div>
  );
};
