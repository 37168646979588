import React from "react";
import { useRevenueTransactions } from "../../../api/endpoints/get-revenue-transactions";
import { Card, AreaChart } from "@tremor/react";
import { formatDate } from "../../../utils/date";
import { formatToMXN } from "../../../utils/money";
import { BaseProps } from "../../../utils/types";

interface CashVSCreditByDayProps extends BaseProps {}

const sortDate = (
  a: { date: string; efectivo: number; tarjeta: number },
  b: { date: string; efectivo: number; tarjeta: number }
) => {
  return a.date.localeCompare(b.date);
};

export const CashVSCreditByDay = ({
  branches,
  from,
  to,
}: CashVSCreditByDayProps) => {
  const { data, isLoading } = useRevenueTransactions({ branches, from, to });

  const { groupedByDay } = React.useMemo(() => {
    if (!data || isLoading) {
      return { groupedByDay: [] };
    }

    const grouped = data.reduce((acc, item) => {
      const date = formatDate(item.created_at);
      if (!acc[date]) {
        acc[date] = { cashAmount: 0, creditAmount: 0 };
      }

      if (item.is_credit_card) {
        acc[date].creditAmount += item.amount;
      } else {
        acc[date].cashAmount += item.amount;
      }
      return acc;
    }, {} as Record<string, { cashAmount: number; creditAmount: number }>);

    return {
      groupedByDay: Object.entries(grouped)
        .map(([key, value]) => {
          return {
            date: key,
            efectivo: value.cashAmount,
            tarjeta: value.creditAmount,
          };
        })
        .sort(sortDate),
    };
  }, [data, isLoading]);

  return (
    <Card>
      <h3 className="text-base font-bold"> Metodo de pago por dia </h3>
      <AreaChart
        data={groupedByDay}
        index="date"
        categories={["efectivo", "tarjeta"]}
        yAxisWidth={48}
        colors={["emerald", "amber"]}
        valueFormatter={formatToMXN}
        onValueChange={(v) => console.log(v)}
      />
    </Card>
  );
};
